import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import React, { useContext, useEffect, useState } from 'react';
import { DrawerContext } from 'context/DrawerContext/DrawerContext';
import { api } from 'common';
import { useDispatch } from 'react-redux';
import FileUploader from './components/FileUploader';

const CarForm = ({ id }) => {
  console.log('id', id);
  const { handleCloseDrawer } = useContext(DrawerContext);
  const { updateCarData, fetchUsersOnce, getCarData } = api;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      vehicleMake: '',
      vehicleModel: '',
      vehicleNumber: '',
      other_info: '',
    },
  });

  const onSubmit = data => {
    try {
      const newData = {
        ...data,
        id: data.carId,
        carImgBlob: formData.carImgBlob,
      };

      dispatch(updateCarData(newData));
      dispatch(fetchUsersOnce());
      reset();
      handleCloseDrawer();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await getCarData(id);
      const objectEntry = Object.entries(response)[0][0];
      setFormData(response[objectEntry]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    if (formData && Object.keys(formData).length > 0) {
      reset(formData);
    }
  }, [formData, reset]);
  return (
    <Box>
      <Typography
        variant="h5"
        component="h2"
        sx={{ marginBottom: 2, marginTop: 2 }}
      >
        Editar Carro
      </Typography>

      <Divider sx={{ marginBottom: 4 }} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FileUploader
              label="Subir imagen del carro"
              updateFile={file => {
                setFormData({ ...formData, carImgBlob: file });
              }}
              deleteFile={() => {
                setFormData({ ...formData, carImgBlob: null });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register('vehicleMake', { required: true })}
              label="Marca"
              defaultValue={formData.vehicleMake}
              fullWidth
              error={errors.vehicleMake}
              helperText={errors.vehicleMake ? 'Este campo es obligatorio' : ''}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              {...register('vehicleModel', { required: true })}
              label="Modelo"
              defaultValue={formData.vehicleModel}
              fullWidth
              error={errors.vehicleModel}
              helperText={
                errors.vehicleModel ? 'Este campo es obligatorio' : ''
              }
              type="number"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              {...register('vehicleReference', { required: true })}
              label="Linea"
              defaultValue={formData.vehicleReference}
              fullWidth
              error={errors.vehicleReference}
              helperText={
                errors.vehicleReference ? 'Este campo es obligatorio' : ''
              }
              InputLabelProps={{
                shrink: !!formData.vehicleReference || watch('other_info'),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              {...register('vehicleNumber', { required: true })}
              label="Placa"
              defaultValue={formData.vehicleNumber}
              fullWidth
              error={errors.vehicleNumber}
              helperText={
                errors.vehicleNumber ? 'Este campo es obligatorio' : ''
              }
              InputLabelProps={{
                shrink: !!formData.vehicleNumber || watch('other_info'),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...register('other_info')}
              label="Información adicional"
              defaultValue={formData.other_info}
              fullWidth
              InputLabelProps={{
                shrink: !!formData.other_info || watch('other_info'),
              }}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 2,
          }}
        >
          <Button type="submit" variant="contained" color="primary">
            Actualizar
          </Button>
          <Button onClick={handleCloseDrawer} color="error" variant="outlined">
            Cancelar
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default CarForm;
