import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import React, { useContext, useEffect, useState } from 'react';
import { DrawerContext } from 'context/DrawerContext/DrawerContext';
import { api } from 'common';
import { useDispatch } from 'react-redux';
import { CloudUpload } from '@mui/icons-material';
import styled from '@emotion/styled';
import FileUploader from './components/FileUploader';
import { convertFileToBlob } from 'utils';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
});

const UserForm = ({ data }) => {
  const { handleCloseDrawer } = useContext(DrawerContext);
  const { editUser, fetchUsersOnce, updateUserDocumentation } = api;
  const dispatch = useDispatch();
  const { uid } = data;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      mobile: '',
      email: '',
      identification: '',
    },
  });

  const [docsPictures, setDocsPictures] = useState([]);

  const onSubmit = data => {
    try {
      dispatch(editUser(uid, data));

      docsPictures.forEach(doc => {
        doc.imageBlob = convertFileToBlob(doc.imageBlob);
        dispatch(updateUserDocumentation(uid, doc));
      });

      dispatch(fetchUsersOnce());
      handleCloseDrawer();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    reset(data);
  }, [data]);

  return (
    <Box>
      <Typography
        variant="h5"
        component="h2"
        sx={{ marginBottom: 2, marginTop: 2 }}
      >
        Editar Usuario
      </Typography>

      <Divider sx={{ marginBottom: 4 }} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FileUploader
              label="Foto de perfil"
              updateFile={file => {
                setDocsPictures([
                  ...docsPictures,
                  {
                    id: 8,
                    imageBlob: file,
                  },
                ]);
              }}
              deleteFile={() => {
                setDocsPictures(docsPictures.filter(doc => doc.id !== 8));
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register('firstName', { required: true })}
              label="Nombres"
              defaultValue={data.firstName}
              fullWidth
              error={errors.firstName}
              helperText={errors.firstName ? 'Este campo es obligatorio' : ''}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              {...register('lastName', { required: true })}
              label="Apellidos"
              defaultValue={data.lastName}
              fullWidth
              error={errors.lastName}
              helperText={errors.lastName ? 'Este campo es obligatorio' : ''}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...register('email', { required: true })}
              label="Correo electrónico"
              defaultValue={data.email}
              fullWidth
              error={errors.email}
              helperText={errors.email ? 'Este campo es obligatorio' : ''}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              {...register('identification', { required: true })}
              label="Número de identificación"
              defaultValue={data.identification}
              fullWidth
              error={errors.identification}
              helperText={
                errors.identification ? 'Este campo es obligatorio' : ''
              }
              InputLabelProps={{
                shrink: !!data.mobile || watch('mobile'),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              {...register('mobile', { required: true })}
              label="Teléfono"
              defaultValue={data.mobile}
              fullWidth
              error={errors.mobile}
              helperText={errors.mobile ? 'Este campo es obligatorio' : ''}
              InputLabelProps={{
                shrink: !!data.mobile || watch('mobile'),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography>DOCUMENTOS</Typography>
          </Grid>

          <Grid item xs={6}>
            <FileUploader
              label="Subir licencia frontal"
              updateFile={file => {
                setDocsPictures([
                  ...docsPictures,
                  {
                    id: 1,
                    imageBlob: file,
                  },
                ]);
              }}
              deleteFile={() => {
                setDocsPictures(docsPictures.filter(doc => doc.id !== 1));
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FileUploader
              label="Subir licencia trasera"
              updateFile={file => {
                setDocsPictures([
                  ...docsPictures,
                  {
                    id: 2,
                    imageBlob: file,
                  },
                ]);
              }}
              deleteFile={() => {
                setDocsPictures(docsPictures.filter(doc => doc.id !== 2));
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FileUploader
              label="Subir tarjeta operativa"
              updateFile={file => {
                setDocsPictures([
                  ...docsPictures,
                  {
                    id: 3,
                    imageBlob: file,
                  },
                ]);
              }}
              deleteFile={() => {
                setDocsPictures(docsPictures.filter(doc => doc.id !== 3));
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FileUploader
              label="Subir tarjeta de propiedad frontal"
              updateFile={file => {
                setDocsPictures([
                  ...docsPictures,
                  {
                    id: 4,
                    imageBlob: file,
                  },
                ]);
              }}
              deleteFile={() => {
                setDocsPictures(docsPictures.filter(doc => doc.id !== 4));
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FileUploader
              label="Subir tarjeta de propiedad trasera"
              updateFile={file => {
                setDocsPictures([
                  ...docsPictures,
                  {
                    id: 5,
                    imageBlob: file,
                  },
                ]);
              }}
              deleteFile={() => {
                setDocsPictures(docsPictures.filter(doc => doc.id !== 5));
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FileUploader
              label="Subir tarjeton"
              updateFile={file => {
                setDocsPictures([
                  ...docsPictures,
                  {
                    id: 6,
                    imageBlob: file,
                  },
                ]);
              }}
              deleteFile={() => {
                setDocsPictures(docsPictures.filter(doc => doc.id !== 6));
              }}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 2,
          }}
        >
          <Button type="submit" variant="contained" color="primary">
            Actualizar
          </Button>
          <Button onClick={handleCloseDrawer} color="error" variant="outlined">
            Cancelar
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default UserForm;
