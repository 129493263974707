import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { api } from 'common';
import { DrawerContext } from 'context/DrawerContext/DrawerContext';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import FileUploader from './components/FileUploader';
import Swal from 'sweetalert2';

const CarCreateForm = ({ userId }) => {
  const { handleCloseDrawer } = useContext(DrawerContext);
  const { addCar, fetchUsersOnce, updateCarData } = api;
  const dispatch = useDispatch();
  const [city, setCity] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      vehicleMake: '',
      vehicleModel: '',
      vehicleNumber: '',
      other_info: '',
      vehicleCity: '',
      carImgBlob: null,
    },
  });

  const onSubmit = async data => {
    let newCar = {
      active: true,
      approved: false,
      carType: 'Taxi',
      car_image: null,
      createdAt: new Date().getTime(),
      driver: userId,
      fleetadmin: null,
      vehicleMake: data.vehicleMake,
      vehicleModel: data.vehicleModel,
      vehicleNumber: data.vehicleNumber,
      vehicleReference: data.vehicleReference,
      vehicleCity: city,
      other_info: data.other_info,
    };
    try {
      const carId = await dispatch(addCar(newCar));

      newCar = {
        ...newCar,
        id: carId,
        carImgBlob: data.carImgBlob,
      };

      dispatch(updateCarData(newCar));
      reset();
      handleCloseDrawer();
      Swal.fire({
        icon: 'success',
        title: '¡Carro creado con éxito!',
        showConfirmButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          dispatch(fetchUsersOnce());
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '¡Error al crear el carro!',
        text: error.message,
        timer: 3000,
      });
    }
  };

  return (
    <Box>
      <Typography
        variant="h5"
        component="h2"
        sx={{ marginBottom: 2, marginTop: 2 }}
      >
        Crear carro nuevo carro para el taxista
      </Typography>

      <Divider sx={{ marginBottom: 4 }} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FileUploader
              label="Subir imagen del carro"
              updateFile={file => {
                setValue('carImgBlob', file, { shouldValidate: false });
              }}
              deleteFile={() => {
                setValue('carImgBlob', null, { shouldValidate: false });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register('vehicleMake', { required: true })}
              label="Marca"
              fullWidth
              error={errors.vehicleMake}
              helperText={errors.vehicleMake ? 'Este campo es obligatorio' : ''}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              {...register('vehicleReference', { required: true })}
              label="Linea"
              fullWidth
              error={errors.vehicleReference}
              helperText={
                errors.vehicleReference ? 'Este campo es obligatorio' : ''
              }
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              {...register('vehicleModel', { required: true })}
              label="Modelo"
              fullWidth
              error={errors.vehicleModel}
              helperText={
                errors.vehicleModel ? 'Este campo es obligatorio' : ''
              }
              type="number"
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              {...register('vehicleNumber', { required: true })}
              label="Placa"
              fullWidth
              error={errors.vehicleNumber}
              helperText={
                errors.vehicleNumber ? 'Este campo es obligatorio' : ''
              }
            />
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Ciudad</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={city}
                label="Ciudad"
                onChange={event => {
                  setValue('vehicleCity', event.target.value);
                  setCity(event.target.value);
                }}
              >
                <MenuItem value={'Cúcuta'}>Cúcuta</MenuItem>
                <MenuItem value={'Bogotá'}>Bogotá</MenuItem>
                <MenuItem value={'Sevilla'}>Sevilla</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...register('other_info')}
              label="Información adicional"
              fullWidth
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 2,
          }}
        >
          <Button type="submit" variant="contained" color="primary">
            Crear
          </Button>
          <Button onClick={handleCloseDrawer} color="error" variant="outlined">
            Cancelar
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default CarCreateForm;
