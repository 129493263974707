import React, { useState } from 'react';
import { DrawerContext } from './DrawerContext';

const DrawerProvider = ({ children }) => {
  const [content, setContent] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    resetContent();
    setOpenDrawer(false);
  };

  const updateContent = newContent => {
    setContent(newContent);
  };

  const resetContent = () => {
    setContent(null);
  };

  return (
    <DrawerContext.Provider
      value={{
        openDrawer,
        content,
        handleOpenDrawer,
        handleCloseDrawer,
        updateContent,
        resetContent,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;


   
  

