import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './views/LandingPage.js';
import LoginPage from './views/LoginPage.js';
import PrivacyPolicy from './views/PrivacyPolicy.js';
import AboutUs from './views/AboutUs';
import AuthLoading from './views/AuthLoading';
import { Provider } from 'react-redux';
import ProtectedRoute from './views/ProtectedRoute';
import MyProfile from './views/MyProfile';
import Dashboard from './views/Dashboard';
import CarTypes from './views/CarTypes';
import Promos from './views/Promos';
import Notifications from './views/Notifications';
import Settings from './views/Settings.js';
import CancellationReasons from './views/CancellationReasons';
import AddMoney from './views/AddMoney';
import Withdraws from './views/Withdraws';
import AllReports from './views/AllReports';
import { FirebaseProvider, store } from 'common';
import { FirebaseConfig } from './config/FirebaseConfig';
import { GoogleMapApiConfig } from './config/GoogleMapApiConfig';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ContactUs from './views/ContactUs';
import UserWallet from './views/UserWallet';
import CarsList from './views/CarsList';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';
import { useJsApiLoader } from '@react-google-maps/api';
import TermCondition from 'views/TermCondition.js';
import { Roles } from 'models/index.js';
import AddBookingPage from 'views/AddBooking/AddBookingPage.jsx';
import BookingHistoryPage from 'views/BookingHistory/BookingHistoryPage.js';
import { AdminPage } from 'views/Admins/AdminPage.js';
import PassengersPage from 'views/Passengers/PassengersPage.js';
import FlotasPage from 'views/Flotas/FlotasPage.js';
import DriverPage2 from 'views/Drivers/DriverPage2.js';
import DrawerProvider from 'context/DrawerContext/DrawerProvider.js';
import 'react-photo-view/dist/react-photo-view.css';
import DonwloadPage from 'views/Download/DownloadPage.js';

const libraries = ['geometry', 'drawing', 'places'];

i18n.use(initReactI18next).init({
  resources: {},
  fallbackLng: 'en',
  ns: ['translations'],
  defaultNS: 'translations',
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  useJsApiLoader({
    id: 'google-map',
    googleMapsApiKey: GoogleMapApiConfig,
    libraries,
  });

  const theme = createTheme();

  return (
    <Provider store={store}>
      <FirebaseProvider config={FirebaseConfig}>
        <ThemeProvider theme={theme}>
          <AuthLoading>
            <DrawerProvider>
              <BrowserRouter>
                <Routes>
                  <Route
                    path="/dashboard"
                    element={
                      <ProtectedRoute permit={'admin,fleetadmin,manager'}>
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/bookings"
                    element={
                      <ProtectedRoute
                        permit={
                          'customer,admin,driver,fleetadmin,cabCompany,manager'
                        }
                      >
                        <BookingHistoryPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <ProtectedRoute
                        permit={
                          'customer,admin,driver,fleetadmin,cabCompany,manager'
                        }
                      >
                        <MyProfile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/cartypes"
                    element={
                      <ProtectedRoute permit={Roles.ADMIN}>
                        <CarTypes />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    path="/cars"
                    element={
                      <ProtectedRoute permit={'admin,fleetadmin,driver'}>
                        <CarsList />
                      </ProtectedRoute>
                    }
                  /> */}
                  <Route
                    path="/cancelreasons"
                    element={
                      <ProtectedRoute permit={'admin,manager'}>
                        <CancellationReasons />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/addbookings"
                    element={
                      <ProtectedRoute
                        permit={'admin,fleetadmin,customer,cabCompany,manager'}
                      >
                        <AddBookingPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/promos"
                    element={
                      <ProtectedRoute permit={Roles.ADMIN}>
                        <Promos />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/drivers"
                    element={
                      <ProtectedRoute permit={'admin,fleetadmin,manager'}>
                        <DriverPage2 />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/passengers"
                    element={
                      <ProtectedRoute permit={'admin,fleetadmin,manager'}>
                        <PassengersPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admins"
                    element={
                      <ProtectedRoute permit={'admin,fleetadmin'}>
                        <AdminPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/flotas"
                    element={
                      <ProtectedRoute permit={'admin,fleetadmin'}>
                        <FlotasPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/notifications"
                    element={
                      <ProtectedRoute permit={Roles.ADMIN}>
                        <Notifications />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/addtowallet"
                    element={
                      <ProtectedRoute permit={Roles.ADMIN}>
                        <AddMoney />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/userwallet"
                    element={
                      <ProtectedRoute permit={'customer,driver'}>
                        <UserWallet />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/withdraws"
                    element={
                      <ProtectedRoute permit={Roles.ADMIN}>
                        <Withdraws />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/allreports"
                    element={
                      <ProtectedRoute permit={'admin,fleetadmin'}>
                        <AllReports />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <ProtectedRoute permit={Roles.ADMIN}>
                        <Settings />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route path="/download" element={<DonwloadPage />} />
                  <Route path="/about-us" element={<AboutUs />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/term-condition" element={<TermCondition />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/" element={<LandingPage />} />
                </Routes>
              </BrowserRouter>
            </DrawerProvider>
          </AuthLoading>
        </ThemeProvider>
      </FirebaseProvider>
    </Provider>
  );
}

export default App;
