import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { api } from 'common';
import { CircularLoading } from 'components';
import { Button } from 'components';
import { DrawerContext } from 'context/DrawerContext/DrawerContext';
import { CarForm } from 'forms';
import { useContext, useEffect, useState } from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';

const ViewCarDetails = ({ carId, ...props }) => {
  const [open, setOpen] = useState(false);
  const { getCarData } = api;
  const [carData, setCarData] = useState({});
  const [loading, setLoading] = useState(false);
  const { handleOpenDrawer, updateContent } = useContext(DrawerContext);

  const fetchCarData = async () => {
    setLoading(true);
    try {
      const response = await getCarData(carId);
      if (response) {
        const objectEntry = Object.entries(response)[0][0];
        setCarData(response[objectEntry]);
      } else {
        setCarData(null);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const editCar = () => {
    updateContent(<CarForm id={carId} />);
    handleOpenDrawer();
    setOpen(false);
  };

  useEffect(() => {
    fetchCarData();
    setLoading(false);
  }, [carId]);

  return (
    <>
      <GridActionsCellItem {...props} onClick={() => setOpen(true)} />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: 'md',
          },
        }}
      >
        {!carData ? (
          <CircularLoading />
        ) : (
          <>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <PhotoProvider>
                    <PhotoView src={carData.car_image}>
                      <img
                        alt="taxi"
                        src={carData.car_image}
                        style={{
                          width: 350,
                        }}
                      />
                    </PhotoView>
                  </PhotoProvider>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      backgroundColor: carData.approved ? '#4caf50' : '#f44336',
                      color: '#fff',
                      paddingInline: 2,
                      borderRadius: 2,
                    }}
                  >
                    {carData.approved ? 'Aprobado' : 'No aprobado'}
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      fontWeight={'bold'}
                      gutterBottom
                    >
                      Marca
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {carData.vehicleMake}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box>
                    <Typography
                      variant="body1"
                      fontWeight={'bold'}
                      gutterBottom
                    >
                      Modelo
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {carData.vehicleModel}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box>
                    <Typography
                      variant="body1"
                      fontWeight={'bold'}
                      gutterBottom
                    >
                      Placa
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {carData.vehicleNumber}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box>
                    <Typography
                      variant="body1"
                      fontWeight={'bold'}
                      gutterBottom
                    >
                      Referencia
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {carData.vehicleReference}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box>
                    <Typography
                      variant="body1"
                      fontWeight={'bold'}
                      gutterBottom
                    >
                      Ciudad
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {carData.vehicleCity}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box>
                    <Typography
                      variant="body1"
                      fontWeight={'bold'}
                      gutterBottom
                    >
                      Información adicional
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {carData.other_info}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => editCar()}>Editar carro</Button>
              <Button onClick={() => setOpen(false)}>Cerrar</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ViewCarDetails;
