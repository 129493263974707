import { HeaderLinks } from 'components';
import { Parallax } from 'components';
import { Header } from 'components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import styles from '../../styles/staticPages.js';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { Android, Apple } from '@mui/icons-material';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const DonwloadPage = props => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;
  const settings = useSelector(state => state.settingsdata.settings);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const playStoreUrl =
      'https://play.google.com/store/apps/details?id=com.taxiok.services';
    const appStoreUrl =
      'https://apps.apple.com/co/app/taxiok/id6470965351?l=en-GB';

    if (/android/i.test(userAgent)) {
      // Redirigir a Google Play en Android
      window.location.href = playStoreUrl;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Redirigir a la App Store en iOS
      window.location.href = appStoreUrl;
    }
  }, []);

  return (
    <div style={{ margin: '-8px' }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white',
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require('web-app/src/assets/img/header-back.jpg')}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <br />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              gap: '1rem',
              padding: '1rem',
            }}
          >
            {/* Card para iOS */}
            <Card style={{ maxWidth: 300 }}>
              <CardContent>
                <Apple style={{ fontSize: 50, color: '#000' }} />
                <Typography variant="h5" component="div" gutterBottom>
                  Descarga para iOS
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Disfruta nuestra aplicación en tu dispositivo iOS. Disponible
                  en la App Store.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '1rem' }}
                  href="https://apps.apple.com/co/app/taxiok/id6470965351?l=en-GB "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ir a la App Store
                </Button>
              </CardContent>
            </Card>

            {/* Card para Android */}
            <Card style={{ maxWidth: 300 }}>
              <CardContent>
                <Android style={{ fontSize: 50, color: '#3ddc84' }} />
                <Typography variant="h5" component="div" gutterBottom>
                  Descarga para Android
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Obtén nuestra aplicación en tu dispositivo Android. Disponible
                  en Google Play.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '1rem' }}
                  href="https://play.google.com/store/apps/details?id=com.taxiok.services"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ir a Google Play
                </Button>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonwloadPage;
